import React, { useEffect, useState } from "react";
import { Link } from 'gatsby';
import { FaBars, FaTimes } from "react-icons/fa";
import { Button } from "./Button";
import { IconContext } from "react-icons/lib";
import logo from '../../images/ltg-partners.svg';

const HeaderPartners = () => {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
  
    const handleClick = () => {
      document.getElementById('NavBar').classList.add('whiteNav');
      if(click === true){
        document.getElementById('NavBar').classList.remove('whiteNav');
      }
      else{
        document.getElementById('NavBar').classList.add('whiteNav');
      }
      setClick(!click);
    }
    const closeMobileMenu = () => {       
      setClick(false)
    };
    
    const showButton = () => {
      if (window.innerWidth <= 1150) {
        setButton(false);
        
      } else {
        setButton(true);
      }
      
    };
  
    useEffect(() => {
      showButton();
      setInterval(() => {
        if(window.pageYOffset > 80){
          document.getElementById('NavBar').classList.add("fixedNavBar");        
        }
        else{
          document.getElementById('NavBar').classList.remove("fixedNavBar");        
        }
      }, 200);
    }, []);
return (
<>
<div className="NavContainer" id="NavBar">
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <div className="navbar-container container-fluid">
            
            <Link to="/" className="navbar-logo" style={{zIndex: '10001'}}>
              <img src={logo} className="logo-web partnership" alt="LaundryToGo" />
            </Link>
           
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
        
              
              <li className="nav-menu-item">
                <a href="tel:+16477224585" className="btn-link2" onClick={closeMobileMenu}>
                  <Button buttonStyle="btn--call">
                    <i class="fa fa-phone fa-1x" />&nbsp;&nbsp;&nbsp;(647) 722-4585
                  </Button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </IconContext.Provider>
    </div>
    
</>
);
};

export default HeaderPartners;
