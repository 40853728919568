import React, { useState } from 'react';
import { Radio } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';

const HireForm = props => {
  const { handleSubmit, pristine, reset, submitting, classes } = props;
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [hourShift, setHourShift] = useState('');
  const [SuccessMsg, setSuccessMsg] = useState(null);
  const [checked, setChecked] = React.useState(false);
  const [address, setAddress] = React.useState('');

  const agreeTerms = event => {
    setChecked(event.target.checked);
  };

  const handleShiftChange = event => {
    setHourShift(event.target.value);
  };
  function submitDATA(e) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (
      fname.length <= 4 ||
      lname.length <= 4 ||
      number.length <= 6 ||
      email.length <= 9 ||
      address.length <= 10
    ) {
      setSuccessMsg('fieldsMissing');
      setTimeout(() => {
        setSuccessMsg(null);
      }, 6000);
    }
    if (checked === false) {
      setSuccessMsg('termsMissing');
      setTimeout(() => {
        setSuccessMsg(null);
      }, 6000);
    }
    if (hourShift === '') {
      setSuccessMsg('hourShiftMissing');
      setTimeout(() => {
        setSuccessMsg(null);
      }, 6000);
    } else {
      if (checked === true) {
        const url = process.env.GATSBY_API_URL + '/partner';
        const data = {
          fname: fname,
          lname: lname,
          number: number,
          email: email,
          hourShift: hourShift,
          address: address
        };
        const headers = {
          'Access-Control-Allow-Origin': '*',
        };
  
        axios.post(url, data, { headers: headers }).then(res => {
          if (res.data === 'Success') {
            setFName('');
            setLName('');
            setNumber('');
            setEmail('');
            setChecked('');
            setAddress('');
            setSuccessMsg(true);
            setTimeout(() => {
              setSuccessMsg(null);
            }, 6000);
          } else {
            setSuccessMsg(false);
            setTimeout(() => {
              setSuccessMsg(null);
            }, 6000);
          }
        });
      } else {
        if (checked === false) {
          setSuccessMsg('termsMissing');
          setTimeout(() => {
            setSuccessMsg(null);
          }, 6000);
        }
        if (hourShift === null) {
          setSuccessMsg('hourShiftMissing');
          setTimeout(() => {
            setSuccessMsg(null);
          }, 6000);
        }
      }
    }
  }
  return (
    <section class="hireContainerForm clearfix">
      <div class="col2 column1 first">
        <div class="sec2contactform">
          <h3
            className={'hire-Title'}
            style={{ color: '#000', fontSize: '35px' }}
          >
            Become a partner
          </h3>
          <form>
            <div class="clearfix">
              <input
                class="colHalf first"
                title="Your Full Name"
                required
                id="fname"
                value={fname}
                name="name"
                type="text"
                placeholder="First Name *"
                onChange={event => {
                  setFName(event.target.value);
                }}
              />
              <input
                class="colHalf last"
                title="Your Full Name"
                required
                id="lname"
                value={lname}
                name="name"
                type="text"
                placeholder="Last Name  *"
                onChange={event => {
                  setLName(event.target.value);
                }}
              />
              <div class="clearfix">
                <input
                  style={{ width: '100%' }}
                  required
                  class="colFull first"
                  title="Your Email Address"
                  value={email}
                  name="email"
                  type="Email"
                  placeholder="Email *"
                  onChange={event => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
              <div class="clearfix">
                <input
                  style={{ width: '100%' }}
                  required
                  className="colFull first"
                  title="Address"
                  value={address}
                  name="address"
                  type="text"
                  placeholder="Address *"
                  onChange={event => {
                    setAddress(event.target.value);
                  }}
                />
              </div>
              <input
                className="colFull last"
                required
                id="number"
                value={number}
                title="Mobile Phone Number"
                name="number"
                type="text"
                placeholder="Mobile Phone Number *"
                onChange={event => {
                  setNumber(event.target.value);
                }}
              />
            </div>
            <br />
            <div class="clearfix radioGroup">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                  value={hourShift}
                  onChange={handleShiftChange}
                >
                  <div className={'radioGroupSingle'}>
                    <span>
                      <b>Full Time</b>
                      <br /> (12 Hours)
                    </span>
                    <FormControlLabel
                      value="12 Hours"
                      control={<Radio color="primary" />}
                    />
                  </div>
                  <div className={'radioGroupSingle'}>
                    <span>
                      <b>Half Time</b>
                      <br /> (6 Hours)
                    </span>
                    <FormControlLabel
                      value="6 Hours"
                      control={<Radio color="primary" />}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
              <br />
              <div className="checkbox-Wrapper">
                <FormControlLabel
                  value="24"
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={agreeTerms}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  }
                  label="I confirm that I have a valid driver's license and a vehicle. I also
                    agree that LaundryToGo or its representatives may contact me by
                    email, phone, or SMS (including by automated means) at the email
                    address or number I provide."
                />
              </div>
            </div>
            <div class="clearfix">
              <button
                className="buttonContact btn--outline btn--medium"
                onClick={submitDATA}
              >
                Submit
              </button>
              <div className="buttonCall btn--outline btn--medium">
                <a href="tel:+16477224585">Call Us</a>
              </div>
            </div>
          </form>
          <br />
          {SuccessMsg === true ? (
            <p>Thank you for the submission. We will get back to you shortly</p>
          ) : (
            ''
          )}
          {SuccessMsg === false ? (
            <p>Some Error Occured Please Try Again!</p>
          ) : (
            ''
          )}
          {SuccessMsg === 'fieldsMissing' ? (
            <p style={{ color: 'red', fontSize: '21px' }}>
              Please Fill All Mandatory Fields!
            </p>
          ) : (
            ''
          )}
          {SuccessMsg === 'termsMissing' ? (
            <p style={{ color: 'red', fontSize: '21px' }}>
              Please agree to our terms!
            </p>
          ) : (
            ''
          )}
          {SuccessMsg === 'hourShiftMissing' ? (
            <p style={{ color: 'red', fontSize: '21px' }}>
              Please select the hours shift!
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
};

export default HireForm;
